import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


export function SuperfanActionsModal(props) {

    const details = props.data

    return (
        <Modal
            className="fade"
            {...props}
            size="l"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Superfan actions counts
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    {details && details.map((action) => (
                        <Row className='modalRow'>
                            <Col xs={10} md={7}> {action?.count} </Col>
                            <Col xs={16} md={15}> {action['fanMetric.name']}s </Col>
                        </Row>))}
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}