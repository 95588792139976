import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { showMessage } from 'utils/notification.util'
import { getFanRequirements, updateFanRequirements } from 'services/FanRequirementService'

const useFanRequirementsForm = () => {
	const [data, setData] = useState([])

	const afterFetchRequirement = (response, error) => {
		if (!error) {
			setData(response)
		}
	}
	const [{ loading: loadingRequirement }] = useAxios(getFanRequirements(), afterFetchRequirement)

	const afterSave = (_data, error) => {
		if (!error) {
			const message = 'Fan requirements updated successfully!'
			showMessage({ message })
		}
	}
	const [{ loading: saving }, saveChanges] = useAxios(updateFanRequirements(data), afterSave)

	const handleChange = (index, threshold) => {
		setData(prevData => {
			const newData = [...prevData]
			newData[index] = {
				...newData[index],
				threshold
			}
			return newData
		})
	}

	const handleSave = () => {
		saveChanges()
	}

	const loading = saving || loadingRequirement

	return {
		data,
		loading,
		handleSave,
		handleChange
	}
}

export default useFanRequirementsForm
