const buildFormPerkData = (data, edit) => {
	const formData = new FormData()
	const fields = [
		'name',
		'description',
		'projectId',
		'startDate',
		'endDate',
		'qty',
		'image'
	]

	const files = ['image']

	fields.forEach((key) => {
		if (data[key] instanceof File) {
			formData.append(key, data[key], data[key].name)
		} else if (!files.includes(key)) {
			const value = data[key] || null
			formData.append(key, value)
		}
	})
	return formData
}


export const getPaginatedPerksTemplatesService = (params) => ({
	method: 'get',
	url: '/perk/templates/paginated',
	params,
	autoexec: false
})

export const getPerksTemplatesService = () => ({
	method: 'get',
	url: `/perk/templates`,
	autoexec: true
})

export const createPerkTemplateService = (data) => ({
	method: 'post',
	url: '/perk/template',
	data: {...data, durationUnit: undefined, duration: undefined},
	autoexec: false,
})

export const updatePerkTemplateService = (templateId, data) => ({
	method: 'put',
	url: `/perk/template/${templateId}`,
	data: {...data, durationUnit: undefined, duration: undefined},
	autoexec: false,
})

export const getPerksTemplateByIdService = (templateId, autoexec = true) => ({
	method: 'get',
	url: `/perk/template/${templateId}`,
	autoexec,
})

export const deletePerkTemplateService = (templateId) => ({
	method: 'delete',
	url: `/perk/template/${templateId}`,
	autoexec: false,
})

export const getPerksService = (projectId, params) => ({
	method: 'get',
	url: `/perk/paginated/${projectId}`,
	autoexec: true,
	params
})

export const getDistributedPerksService = (params) => ({
	method: 'get',
	url: `/perk/distributed/paginated`,
	autoexec: true,
	params
})

export const getPerkUsersService = (perkId, params) => ({
	method: 'get',
	url: `/perk/${perkId}/users`,
	autoexec: true,
	params
})


export const createPerkService = (data) => {
	const formData = buildFormPerkData({...data, durationUnit: undefined, duration: undefined}, false)
	return {
		method: 'post',
		url: '/perk',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		autoexec: false,
	}
}

export const editPerkService = (perkId, data) => {
	const formData = buildFormPerkData({...data, durationUnit: undefined, duration: undefined}, true)
	return {
		method: 'put',
		url: `/perk/${perkId}`,
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		autoexec: false,
	}
}

export const publishPerkService = (perkId, data) => ({
	method: 'post',
	url: `/perk/publish/${perkId}`,
	data,
	autoexec: false,
})

export const getPerkByIdService = (perkId, autoexec = true) => ({
	method: 'get',
	url: `/perk/${perkId}`,
	autoexec
})


