import { Button } from 'react-bootstrap'
import useFanRequirementsForm from './useFanRequirements'
import { LoadMask } from 'components/common/loadMask'

const FanRequirementsForm = () => {
	const {
		data,
		loading,
		handleSave,
		handleChange,
	} = useFanRequirementsForm()

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">Minimum Fan Requirements to Unlock Superfan Milestones</h4>
							</div>
							<div className="card-body">
								<div className="basic-form">
									{data.map((requirement, index) => (
										<div key={requirement.name} className="form-row mb-3">
											<div className="form-group col-md-8">
												<label>Name</label>
												<input
													value={requirement.name}
													type="text"
													className="form-control"
													readOnly
												/>
											</div>
											<div className="form-group col-md-4">
												<label>Points Threshold</label>
												<input
													value={requirement.threshold}
													type="number"
													className="form-control"
													placeholder="Points Threshold"
													onChange={(e) => {
														handleChange(index, Number(e.target.value))
													}}
												/>
											</div>
										</div>
									))}
									<Button className="mr-2" variant="success" onClick={handleSave}>
										Save
									</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default FanRequirementsForm
