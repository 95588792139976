import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { SearchField } from 'components/common/searchField'
import usePerkUsers from './usePerkUsers'
import { LoadMask } from 'components/common/loadMask'
import { formatDate } from 'utils/date.utils'
import { CustomTable } from 'components/common/customTable'
import PageTitle from 'jsx/layouts/PageTitle'
import styles from './PerkUsers.module.scss'

const PerkUsers = () => {
	const {
		data,
		loading,
        limit,
        setLimit,
		setOffset,
		setSearch,
	} = usePerkUsers()
    
	const columns = [

		{
			header: 'Email',
			key: 'user.email',
		},
		{
			header: 'Display Name',
			key: 'user.displayName',
		},
        {
            header: 'Project',
            key: 'perk.project.title'
        },
		{
			header: 'Perk',
			key: 'perk.name',
		},
		{
			header: 'Credited Date',
			render: (row) => formatDate(row.createdAt, 'inputDate'),
		},
        {
            header: 'Ranking',
            key: 'ranking',
        }
	]

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (		<>
                <PageTitle title={data.projectTitle} />
                <Row className={styles.project_table}>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Perk Users</Card.Title>
                                <SearchField {...{ setSearch }} />
                            </Card.Header>
                            <Card.Body>
                                <CustomTable
                                    {...{
                                        data,
                                        loading,
                                        limit,
                                        setLimit,
                                        setOffset,
                                        columns,
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>)}
		</>
	)
}

export default PerkUsers
