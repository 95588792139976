import { useState, useEffect } from 'react'
import useAxios from 'hooks/useAxios'
import { useParams } from 'react-router-dom'
import { getDistributedPerksService } from 'services/PerksService'

const usePerksDistributedDetails = () => {
    const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const { id } = useParams()
	const [data, setData] = useState({
		rows: [],
		count: 0
	})

	const afterFetchPerks = (data, error) => {
		if (!error) {
			setData({
                count: data.count,
                rows: data.rows.filter(row => new Date(row.endDate.split('T')[0]) <= new Date())
            });
		}
	}
	const [{ loading: loadingPerks }, fetchPerks] = useAxios(getDistributedPerksService({ limit, offset, search }), afterFetchPerks)

    useEffect(() => {
		fetchPerks()
	}, [limit, offset, search])

	const loading = loadingPerks

	return {
		data,
		loading,
        limit,
        projectId: id,
		setLimit,
		setOffset,
		setSearch
	}
}

export default usePerksDistributedDetails
