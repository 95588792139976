import { useState, useEffect } from 'react'
import useAxios from 'hooks/useAxios'
import { useParams } from 'react-router-dom'
import { getPerkUsersService } from 'services/PerksService'

const usePerkUsers = () => {
    const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const { id: perkId } = useParams()
	const [data, setData] = useState({
		rows: [],
		count: 0
	})

	const afterFetchPerkUsers = (data, error) => {
		if (!error) {
			setData(data);
		}
	}
	const [{ loading: loadingPerkUsers }, fetchPerkUsers] = useAxios(getPerkUsersService(perkId, { limit, offset, search }), afterFetchPerkUsers)

    useEffect(() => {
		fetchPerkUsers()
	}, [limit, offset, search])

	const loading = loadingPerkUsers

	return {
		data,
		loading,
        limit,
        perkId,
		setLimit,
		setOffset,
		setSearch
	}
}

export default usePerkUsers
