import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { useHistory, useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import { createPerkTemplateService, updatePerkTemplateService, getPerksTemplateByIdService } from 'services/PerksService'
import _ from 'lodash'

const usePerksTemplatesForm = () => {
	const history = useHistory()
	const { id } = useParams()
	const [data, setData] = useState({
		name: '',
		description: '',
		qty: null,
		startDate: '',
		endDate: '',
		duration: '',
		durationUnit: 'day'
	})


	const afterFetchTemplate = (data, error) => {
		if (!error) {
			data.startDate = data.startDate.split('T')[0];
			data.endDate = data.endDate.split('T')[0];
			const start = new Date(data.startDate).getTime();
			const end = new Date(data.endDate).getTime();
			const diffDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
			
			let duration, durationUnit;
			
			if (diffDays % 30 === 0) {
				duration = diffDays / 30;
				durationUnit = 'month';
			} else if (diffDays % 7 === 0) {
				duration = diffDays / 7;
				durationUnit = 'week';
			} else {
				duration = diffDays;
				durationUnit = 'day';
			}
			
			setData({ ...data, duration, durationUnit, createdAt: undefined, updatedAt: undefined, id: undefined, deletedAt: undefined });
		}
	}
	const [{ loading: loadingTemplate }] = useAxios(getPerksTemplateByIdService(id, !_.isNil(id)), afterFetchTemplate)

	const service = _.isNil(id) ? createPerkTemplateService(data) : updatePerkTemplateService(id, data)
	const afterSave = (_data, error) => {
		if (!error) {
			const message = 'Template updated successfully!'
			showMessage({ message })
			history.push('/perk_program')
		}
	}

	const [{ loading: saving }, saveChanges] = useAxios(service, afterSave)

	const handleChange = (_payload) => {
		const payload = {
			...data,
			..._payload,
		}
		
		if (_payload.duration || _payload.durationUnit || _payload.startDate) {
            if (payload.startDate && payload.duration && payload.durationUnit) {
                const startDate = new Date(payload.startDate)
				
				const addedTimeMs = (payload.durationUnit === 'day' ? 1 : payload.durationUnit === 'week' ? 7 : 30) * payload.duration * 24 * 60 * 60 * 1000;
				const endDate = new Date(startDate.getTime() + addedTimeMs);

				payload.endDate = endDate.toISOString().split('T')[0];
			}
		}
		
		setData(payload)
	}

	const handleCancel = () => {
		history.goBack()
	}

	const handleSave = () => {
		saveChanges()
	}

	const title = _.isNil(id) ? 'Perks Template Details' : 'Edit Perks Template'

	const loading = loadingTemplate || saving

	return {
		data,
		title,
		loading,
		handleSave,
		handleCancel,
		handleChange
	}
}

export default usePerksTemplatesForm
