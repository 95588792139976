export const getFanRequirements = () => ({
	method: 'get',
	url: '/fanRequirement',
	autoexec: true
})

export const updateFanRequirements = (data) => ({
	method: 'put',
	url: '/fanRequirement/threshold',
	data,
	autoexec: false
})