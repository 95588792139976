import { useState, useEffect } from 'react'
import useAxios from 'hooks/useAxios'
import { getSuperfanAnalytics, getSuperfanUsers, getSuperfanAnalyticsActions, getSuperfanAnalyticsMilestones, getSuperfanAnalyticsDaoTiers, getSuperfanPointsActions } from 'services/SuperfanService'
import useAxiosV2 from 'hooks/useAxiosV2'

const useSuperfanAnalytics = () => {
	const [usersSearch, setUsersSearch] = useState('')
	const [usersLimit, setUsersLimit] = useState(5)
	const [usersOffset, setUsersOffset] = useState(0)

	const [projectsSearch, setProjectsSearch] = useState('')
	const [projectsLimit, setProjectsLimit] = useState(5)
	const [projectsOffset, setProjectsOffset] = useState(0)

	const [superfanPointsSearch, setSuperfanPointsSearch] = useState('')
	const [superfanPointsLimit, setSuperfanPointsLimit] = useState(10)
	const [superfanPointsOffset, setSuperfanPointsOffset] = useState(0)

	const [projectsData, setProjectsData] = useState({})
	const [usersData, setUsersData] = useState({})

	const [showActionsDetails, setShowActionsDetails] = useState(false)
	const [selectedProject, setSelectedProject] = useState(null)
	const [actionsData, setActionsData] = useState(null)
	const [milestonesData, setMilestonesData] = useState(null)
	const [daoTiersData, setDaoTiersData] = useState(null)
	const [superfanPointsData, setSuperfanPointsData] = useState(null)

	const { axiosService } = useAxiosV2()

	useEffect(() => {
		fetchProjects()
	}, [projectsSearch, projectsLimit, projectsOffset])

	useEffect(() => {
		fetchUsers()
	}, [usersSearch, usersLimit, usersOffset])

	useEffect(() => {
		fetchSuperfanPoints()
	}, [superfanPointsSearch, superfanPointsLimit, superfanPointsOffset])

	useEffect(() => {
		fetchMilestones()
		fetchDaoTiers()
	}, [])

	const handleShowActionsDetails = (id) => () => {
		axiosService(getSuperfanAnalyticsActions(id), (data, error) => {
			if (!error) {
				setActionsData(data)
			}
		})
		setShowActionsDetails(true)
		setSelectedProject(id)
	}

	const afterFetchUsers = (data, error) => {
		if (!error) {
			setUsersData(data)
		}
	}

	const afterFetchProjects = (data, error) => {
		if (!error) {
			setProjectsData(data)
		}
	}

	const afterFetchMilestones = (data, error) => {
		if (!error) {
			setMilestonesData(data)
		}
	}

	const afterFetchDaoTiers = (data, error) => {
		if (!error) {
			setDaoTiersData(data)
		}
	}

	const afterFetchSuperfanPoints = (data, error) => {
		if (!error) {
			setSuperfanPointsData(data)
		}
	}

	const [{ loading }, fetchProjects] = useAxios(
		getSuperfanAnalytics({limit: projectsLimit, offset: projectsOffset, search: projectsSearch}),
		afterFetchProjects
	)

	const [{ loading: usersLoading }, fetchUsers] = useAxios(
		getSuperfanUsers({limit: usersLimit, offset: usersOffset, search: usersSearch}),
		afterFetchUsers
	)

	const [{ loading: milestonesLoading }, fetchMilestones] = useAxios(
		getSuperfanAnalyticsMilestones(),
		afterFetchMilestones
	)

	const [{ loading: daoTiersLoading }, fetchDaoTiers] = useAxios(
		getSuperfanAnalyticsDaoTiers(),
		afterFetchDaoTiers
	)

	const [{ loading: superfanPointsLoading }, fetchSuperfanPoints] = useAxios(
		getSuperfanPointsActions({limit: superfanPointsLimit, offset: superfanPointsOffset, search: superfanPointsSearch}),
		afterFetchSuperfanPoints
	)

	return {
		projectsData,
		usersData,
		loading: loading || usersLoading || milestonesLoading || daoTiersLoading || superfanPointsLoading,
		setProjectsSearch,
		setProjectsLimit,
		setProjectsOffset,
		setUsersSearch,
		setUsersLimit,
		setUsersOffset,
		handleShowActionsDetails,
		setShowActionsDetails,
		showActionsDetails,
		selectedProject,
		actionsData,
		projectsSearch,
		projectsLimit,
		projectsOffset,
		usersSearch,
		usersLimit,
		usersOffset,
		milestonesData,
		daoTiersData,
		superfanPointsData,
		setSuperfanPointsSearch,
		setSuperfanPointsLimit,
		setSuperfanPointsOffset,
		superfanPointsLimit,
		superfanPointsOffset
	}
}

export default useSuperfanAnalytics
