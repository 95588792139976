import { Row, Col, Card, Button } from 'react-bootstrap'
import { CustomTable } from 'components/common/customTable'
import styles from './SuperfanAnalytics.module.scss'
import PageTitle from 'jsx/layouts/PageTitle'
import { DateTime } from 'luxon'
import { SearchField } from 'components/common/searchField'
import useSuperfanAnalytics from "./useSuperfanAnalytics"
import { SuperfanActionsModal } from './SuperfanActionsModal'

const SuperfanAnalytics = () => {

	const {
		projectsData,
		projectsLimit,
		setProjectsLimit,
		setProjectsOffset,
		setProjectsSearch,
		usersData,
		setUsersSearch,
		usersLimit,
		setUsersLimit,
		setUsersOffset,
		handleShowActionsDetails,
		loading,
		showActionsDetails,
		actionsData,
		setShowActionsDetails,
		milestonesData,
		daoTiersData,
		superfanPointsData,
		setSuperfanPointsSearch,
		setSuperfanPointsLimit,
		setSuperfanPointsOffset,
		superfanPointsLimit
	} = useSuperfanAnalytics()

	console.log({superfanPointsData, usersData})

	const projectsColumns = [
		{
			header: 'Title',
			key: 'title',
		},
		{
			header: 'Total Superfans',
			key: 'superfanAnalytics.totalSuperfans',
		},
		{
			header: 'New Superfans',
			key: 'superfanAnalytics.newSuperfansCount',
		},
		{
			header: 'Total Points',
			render: (row) => `${row.superfanAnalytics.totalPoints.toLocaleString('en-US')}`,
		},
		{
			header: 'Total Fan Staked',
			render: (row) => `${row.superfanAnalytics.totalStakesBySuperfans.toLocaleString('en-US')}`,
		},
		{
			header: 'AVG Stake',
			render: (row) => `${((row.superfanAnalytics.totalStakesBySuperfans / row.superfanAnalytics.uniqueStakers) || 0).toFixed(2)}`,
		},
		{
			header: 'Conversion Rate',
			render: (row) => `${((row.superfanAnalytics.totalSuperfans / (row.superfanAnalytics.totalViews || 1)) * 100).toFixed(2)}%`,
		},
		{
			header: 'Actions Details',
			render: (row) => (
				<div className="d-flex">
					<Button onClick={handleShowActionsDetails(row?.projectId)} className="btn btn-primary shadow btn-xs sharp mr-1">
						<i className="fa fa-eye"></i>
					</Button>
				</div>
			),
		},
	]
	
	const usersColumns = [
		{
			header: 'Email',
			key: 'user.email',
		},
		{
			header: 'Display Name',
			key: 'user.displayName',
		},
		{
			header: 'Superfan Points',
			render: (row) => `${row.totalPoints.toLocaleString('en-US')}`,
		}
	]

	const milestonesColumns = [
		{
			header: 'Milestone',
			key: 'milestoneName',
		},
		{
			header: 'Description',
			key: 'milestoneDescription',
		},
		{
			header: 'Total Superfans',
			key: 'count',
		},
		{
			header: 'Percentage',
			render: (row) => `${((row.count / milestonesData.totalSuperfans) * 100).toFixed(2)}%`,
		},
		{
			header: 'Points Threshold',
			key: 'milestonePoints',
		},
	]

	const daoTiersColumns = [
		{
			header: 'Tier',
			key: 'daoTierName',
		},
		{
			header: 'Total Superfans',
			key: 'count',
		},
		{
			header: 'Percentage',
			render: (row) => `${((row.count / daoTiersData.totalUsers) * 100).toFixed(2)}%`,
		},
		{
			header: 'Points Threshold',
			key: 'daoTierPoints',
		},
	]

	const superfanPointsColumns = [
		{
			header: 'Project',
			key: 'project.title',
		},
		{
			header: 'Email',
			key: 'user.email',
		},
		{
			header: 'Points',
			render: (row) => `${row.pointsStatus ? '+' : '-'}${row.points}`,
		},
		{
			header: 'Date',
			render: (row) => `${DateTime.fromJSDate(new Date(row.createdAt), { zone: 'America/Los_Angeles' }).toFormat('yyyy-MM-dd  HH:mm:ss')}`,
		},
		{
			header: 'Action',
			render: (row) => `${row.fanMetric.name}`,
		}
	]

	return (
		<>
		<PageTitle title={'Superfan Analytics'} />
		<SuperfanActionsModal data={actionsData} show={showActionsDetails} onHide={() => setShowActionsDetails(false)} />


		{milestonesData && <Row className={styles.project_table}>
			<Col lg={12}>
				<Card>
					<Card.Header>
						<Card.Title>Superfan Milestones</Card.Title>
					</Card.Header>
					<Card.Body>
						<CustomTable
							{...{
								data: {rows: milestonesData?.milestonesAnalytics, count: milestonesData?.milestonesAnalytics.length},
								loading,
								limit: 10,
								setLimit: () => {},
								setOffset: () => {},
								columns: milestonesColumns,
							}}
						/>
					</Card.Body>
				</Card>
			</Col>
		</Row>}

		{daoTiersData && <Row className={styles.project_table}>
			<Col lg={12}>
				<Card>
					<Card.Header>
						<Card.Title>Superfan DAO Tiers</Card.Title>
					</Card.Header>
					<Card.Body>
						<CustomTable
							{...{
								data: {rows: daoTiersData?.daoTiersAnalytics, count: daoTiersData?.daoTiersAnalytics.length},
								loading,
								limit: 10,
								setLimit: () => {},
								setOffset: () => {},
								columns: daoTiersColumns,
							}}
						/>
					</Card.Body>
				</Card>
			</Col>
		</Row>}

		<Row className={styles.project_table}>
			<Col lg={12}>
				<Card>
					<Card.Header>
						<Card.Title>Superfan Projects</Card.Title>
						<SearchField {...{ setSearch: setProjectsSearch }} />
					</Card.Header>
					<Card.Body>
						<CustomTable
							{...{
								data: projectsData,
								loading,
								limit: projectsLimit,
								setLimit: setProjectsLimit,
								setOffset: setProjectsOffset,
								columns: projectsColumns,
							}}
						/>
					</Card.Body>
				</Card>
			</Col>
		</Row>
		<Row className={styles.project_table}>
			<Col lg={12}>
				<Card>
					<Card.Header>
						<Card.Title>Superfan Users</Card.Title>
						<SearchField {...{ setSearch: setUsersSearch }} />
					</Card.Header>
					<Card.Body>
						<CustomTable
							{...{
								data: usersData,
								loading,
								limit: usersLimit,
								setLimit: setUsersLimit,
								setOffset: setUsersOffset,
								columns: usersColumns,
							}}
						/>
					</Card.Body>
				</Card>
			</Col>
		</Row>

		{ superfanPointsData && <Row className={styles.project_table}>
			<Col lg={12}>
				<Card>
					<Card.Header>
						<Card.Title>Superfan Points Actions</Card.Title>
						<SearchField {...{ setSearch: setSuperfanPointsSearch }} />
					</Card.Header>
					<Card.Body>
						<CustomTable
							{...{
								data: superfanPointsData,
								loading,
								limit: superfanPointsLimit,
								setLimit: setSuperfanPointsLimit,
								setOffset: setSuperfanPointsOffset,
								columns: superfanPointsColumns,
							}}
						/>
					</Card.Body>
				</Card>
			</Col>
		</Row>}
		
	</>	
	)
}
export default SuperfanAnalytics
