import { useState, useEffect } from 'react'
import useAxios from 'hooks/useAxios'
import { useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import useAxiosV2 from 'hooks/useAxiosV2'
import { getPerksService, publishPerkService } from 'services/PerksService'

const useProjectPerksDetails = () => {
	const { axiosService } = useAxiosV2()
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const { id } = useParams()
	const [data, setData] = useState({
		rows: [],
		count: 0,
		projectTitle: ''
	})

	const afterPublishPerk = (data, error) => {
		if (!error) {
			showMessage({ message: 'Perk publish status updated successfully!' })
			fetchPerks()
		}
	}

	const afterFetchPerks = (data, error) => {
		if (!error) {
			setData(data);
		}
	}
	const [{ loading: loadingPerks }, fetchPerks] = useAxios(getPerksService(id, { limit, offset, search }), afterFetchPerks)

	const handlePublishPerk = (perkId, isPublished) => {
		const payload = {
			isPublished
		}
		axiosService(publishPerkService(perkId, payload), afterPublishPerk)
	}

	useEffect(() => {
		fetchPerks()
	}, [limit, offset, search])

	const loading = loadingPerks

	return {
		data,
		loading,
		limit,
		projectId: id,
		setLimit,
		setOffset,
		setSearch,
		handlePublishPerk
	}
}

export default useProjectPerksDetails
