import { useState } from 'react'
import useAxios from 'hooks/useAxios'
import { useHistory, useParams } from 'react-router-dom'
import { showMessage } from 'utils/notification.util'
import { createPerkService, editPerkService, getPerkByIdService, getPerksTemplatesService, getPerksTemplateByIdService } from 'services/PerksService'
import _ from 'lodash'
import { getProjectById } from 'services/ProjectService'

const usePerksForm = () => {
	const history = useHistory()
	const { id, projectId } = useParams()
	const [data, setData] = useState({
        projectId,
		name: '',
		description: '',
		qty: null,
		startDate: '',
		endDate: '',
		duration: '',
		durationUnit: 'day'
	})
	const [project, setProject] = useState({})
	const [templates, setTemplates] = useState([])

	const afterFetchPerk = (_data, error) => {
		if (!error) {
			_data.startDate = _data.startDate.split('T')[0];
			_data.endDate = _data.endDate.split('T')[0];
			const start = new Date(_data.startDate).getTime();
			const end = new Date(_data.endDate).getTime();
			const diffDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
			
			let duration, durationUnit;
			
			if (diffDays % 30 === 0) {
				duration = diffDays / 30;
				durationUnit = 'month';
			} else if (diffDays % 7 === 0) {
				duration = diffDays / 7;
				durationUnit = 'week';
			} else {
				duration = diffDays;
				durationUnit = 'day';
			}
			setData({ ...data, ..._data, duration, durationUnit });
		}
	}

	const afterFetchTemplates = (data, error) => {
		if (!error) {
			setTemplates([{id: 'default'}, ...data])
			// afterFetchPerk(data[0], null)
		}
	}

	const afterFetchProject = (_data, error) => {
		if (!error) {
			setProject(_data)
		}
	}

	const [{ loading: loadingProject }] = useAxios(getProjectById(!_.isNil(projectId), projectId), afterFetchProject)
	const [{ loading: loadingPerk }] = useAxios(getPerkByIdService(id, !_.isNil(id)), afterFetchPerk)
	const [{ loading: loadingTemplates }] = useAxios(getPerksTemplatesService(), afterFetchTemplates)

	const handleTemplateChange = (templateId) => {
		if(templateId !== 'default') {
			const template = templates.find((template) => template.id === templateId)
			afterFetchPerk(template, null)
		} else {
			setData({
				projectId,
				name: '',
				description: '',
				qty: '',
				startDate: '',
				endDate: '',
				duration: '',
				durationUnit: 'day'
			})
		}
	}

	const service = _.isNil(id) ? createPerkService(data) : editPerkService(id, data)
	const afterSave = (_data, error) => {
		if (!error) {
			const message = 'Perk updated successfully!'
			showMessage({ message })
			history.goBack()
		}
	}

	const [{ loading: saving }, saveChanges] = useAxios(service, afterSave)

	const handleChange = (_payload) => {
		const payload = {
			...data,
			..._payload,
		}
		if (_payload.duration || _payload.durationUnit || _payload.startDate) {
            if (payload.startDate && payload.duration && payload.durationUnit) {
                const startDate = new Date(payload.startDate)
				
				const addedTimeMs = (payload.durationUnit === 'day' ? 1 : payload.durationUnit === 'week' ? 7 : 30) * payload.duration * 24 * 60 * 60 * 1000;
				const endDate = new Date(startDate.getTime() + addedTimeMs);

				payload.endDate = endDate.toISOString().split('T')[0];
			}
		}
		
		setData(payload)
	}

	const handleCancel = () => {
		history.goBack()
	}

	const handleSave = () => {
		saveChanges()
	}

	const title = `${project.title} - ${_.isNil(id) ? 'Create Perk' : 'Edit Perk'}`

	const loading = loadingPerk || saving || loadingTemplates || loadingProject

	return {
		data,
		title,
		loading,
		templates,
		handleSave,
		handleCancel,
		handleChange,
		handleTemplateChange
	}
}

export default usePerksForm
