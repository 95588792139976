import { useState } from 'react'
import { Row, Col, Card, Button, Modal } from 'react-bootstrap'
import { SearchField } from 'components/common/searchField'
import useProjectPerksDetails from './useProjectPerksDetails'
import { LoadMask } from 'components/common/loadMask'
import { formatDate } from 'utils/date.utils'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { CustomTable } from 'components/common/customTable'
import PageTitle from 'jsx/layouts/PageTitle'
import styles from './ProjectPerksDetails.module.scss'

const ProjectPerksDetails = () => {
	const {
		data,
		loading,
        limit,
        projectId,
        setLimit,
		setOffset,
		setSearch,
		handlePublishPerk
	} = useProjectPerksDetails()

    const [showImageModal, setShowImageModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)

	const columns = [

		{
			header: 'Name',
			key: 'name',
		},
		{
			header: 'Description',
			key: 'description',
		},
		{
			header: 'Start Date',
			render: (row) => formatDate(row.startDate, 'inputDate'),
		},
		{
			header: 'End Date',
			render: (row) => formatDate(row.endDate, 'inputDate'),
		},
		{
			header: 'Quantity',
			key: 'qty',
		},
        {
			header: 'Image',
			render: (row) => {
				const imageIcon = row.image ? (
                    <img 
                        className={styles.image_icon} 
                        src={row.image} 
                        alt="" 
                        onClick={() => {
                            setSelectedImage(row.image)
                            setShowImageModal(true)
                        }}
                        // style={{ cursor: 'pointer' }}
                    />
                ) : 'No Image'
                
                return imageIcon
			}
		},
		{
			header: 'Action',
			render: (row) => (
				<div className="d-flex">
					<Link to={`${row.projectId}/perks-form/${row.id}`} className="btn btn-primary shadow btn-s sharp mr-1">
						<i className="fa fa-pencil"></i>
					</Link>
					<Button disabled={new Date(row.endDate) < new Date()} variant={`${row.isPublished ? 'danger' : 'secondary'}`} onClick={() => handlePublishPerk(row.id, !row.isPublished)}>
						{row.isPublished ? 'Unpublish' : 'Publish'}
					</Button>
				</div>
			),
		},
	]

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (		<>
                <PageTitle title={data.projectTitle} />
                <Row className={styles.project_table}>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Project Perks</Card.Title>
                                <Link to={`${projectId}/perks-form`}>
                                    <Button className="mr-2" variant="success">
                                        Add Perk
                                    </Button>
                                </Link>
                                <SearchField {...{ setSearch }} />
                            </Card.Header>
                            <Card.Body>
                                <CustomTable
                                    {...{
                                        data,
                                        loading,
                                        limit,
                                        setLimit,
                                        setOffset,
                                        columns,
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>)}
			<Modal
                show={showImageModal}
                onHide={() => setShowImageModal(false)}
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Perk Image</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt="Perk"
                            style={{ maxWidth: '100%', maxHeight: '80vh' }}
                        />
                    )}
                </Modal.Body>
            </Modal>
		</>
	)
}

export default ProjectPerksDetails
