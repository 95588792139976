import React, { useState } from 'react'
import { Row, Col, Card, Modal } from 'react-bootstrap'
import { SearchField } from 'components/common/searchField'
import usePerksDistributedDetails from './usePerksDistributedDetails'
import { LoadMask } from 'components/common/loadMask'
import { formatDate } from 'utils/date.utils'
import { CustomTable } from 'components/common/customTable'
import PageTitle from 'jsx/layouts/PageTitle'
import styles from './PerksDistributedDetails.module.scss'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const PerksDistributedDetails = () => {
	const {
		data,
		loading,
        search,
        limit,
        setLimit,
		setOffset,
		setSearch,
	} = usePerksDistributedDetails()

    const [showImageModal, setShowImageModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState(null)

	const columns = [
        {
            header: 'Project',
            key: 'project.title'
        },
		{
			header: 'Name',
			key: 'name',
		},
		{
			header: 'Description',
			key: 'description',
		},
		{
			header: 'Start Date',
			render: (row) => formatDate(row.startDate, 'inputDate'),
		},
		{
			header: 'End Date',
			render: (row) => formatDate(row.endDate, 'inputDate'),
		},
        {
            header: 'Duration',
            render: (row) => {
                const startDate = new Date(row.startDate).getTime()
                const endDate = new Date(row.endDate).getTime()
                const daysDiff = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24))
                return `${daysDiff} days`
            }
        },
		{
			header: 'Quantity',
			key: 'qty',
		},
        {
            header: 'Views',
            key: 'views',
        },
        {
			header: 'Users',
			render: (row) => {
                return (
                    <Link to={`/perk_program/perks-users/${row.id}`} target="_blank">
                        {row.perkUsers}
                    </Link>
                )
            }
		},
        {
			header: 'Image',
			render: (row) => {
				const imageIcon = row.image ? (
                    <img 
                        className={styles.image_icon} 
                        src={row.image} 
                        alt="" 
                        onClick={() => {
                            setSelectedImage(row.image)
                            setShowImageModal(true)
                        }}
                    />
                ) : 'No Image'
                
                return imageIcon
			}
		},
        {
            header: 'Status',
            key: 'distributionStatus'
        }
	]

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (		<>
                <PageTitle title={data.projectTitle} />
                <Row className={styles.project_table}>
                    <Col lg={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title>Distributed Perks</Card.Title>
                                <SearchField {...{ setSearch, search }} />
                            </Card.Header>
                            <Card.Body>
                                <CustomTable
                                    {...{
                                        data,
                                        loading,
                                        limit,
                                        setLimit,
                                        setOffset,
                                        columns,
                                    }}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>)}
            <Modal
                show={showImageModal}
                onHide={() => setShowImageModal(false)}
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Perk Image</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt="Perk"
                            style={{ maxWidth: '100%', maxHeight: '80vh' }}
                        />
                    )}
                </Modal.Body>
            </Modal>
		</>
	)
}

export default PerksDistributedDetails
