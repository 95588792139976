import { UploadFile } from 'components/common/uploadFile'
import { Button } from 'react-bootstrap'
import usePerksForm from './usePerksForm'
import { LoadMask } from 'components/common/loadMask'

const PerksForm = () => {
	const {
		data,
		title,
		loading,
		templates,
		handleSave,
		handleCancel,
		handleChange,
		handleTemplateChange
	} = usePerksForm()

    const imageValue = typeof data.image === 'string' ? data.image : ''

	return (
		<>
			{loading ? (
				<LoadMask />
			) : (
				<div className="row">
					<div className="col-xl-12 col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">{title}</h4>
								<div className="form-group col-md-6">
												<label>Template</label>
												<select className="form-control" onChange={(e) => {
													handleTemplateChange(e.target.value)
												}}>
													{templates && templates.map((template) => (
														<option value={template.id}>{template.name}</option>
													))}
												</select>
											</div>
							</div>
							<div className="card-body">
								<div className="basic-form">
									<form onSubmit={(e) => e.preventDefault()}>
										<div className="form-row">
											<div className="form-group col-md-8">
												<label>Name</label>
												<input
													value={data.name}
													type="text"
													className="form-control"
													placeholder="Name"
													onChange={(e) => {
														handleChange({ name: e.target.value })
													}}
												/>
											</div>
                                            <div className="form-group col-md-4">
												<label>Quantity</label>
												<input
													value={data.qty}
													type="text"
													className="form-control"
													placeholder="Quantity"
													onChange={(e) => {
														handleChange({ qty: Number(e.target.value) })
													}}
												/>
											</div>
											<div className="form-group col-md-12">
												<label>Description</label>
												<input
													value={data.description}
													type="text"
													className="form-control"
													placeholder="Description"
													onChange={(e) => {
														handleChange({ description: e.target.value })
													}}
												/>
											</div>
											<div className="form-group col-md-2">
												<label>Start Date</label>
												<input
													value={data.startDate}
													type="date"
													className="form-control"
													placeholder="Start Date"
													onChange={(e) => {
														handleChange({ startDate: e.target.value })
													}}
												/>
											</div>
											<div className="form-group ml-3">
												<label>Duration</label>
												<div className="d-flex">
													<input
														value={data.duration}
														type="number"
														className="form-control col-md-4"
														placeholder="Duration"
														onChange={(e) => {
															handleChange({ duration: Number(e.target.value) })
														}}
													/>
													<select
														value={data.durationUnit}
														className="form-control col-md-5 ml-2"
														onChange={(e) => {
															handleChange({ durationUnit: e.target.value })
														}}
													>
														<option value="day">Day</option>
														<option value="week">Week</option>
														<option value="month">Month</option>
													</select>
												</div>
											</div>
                                            <div className="input-group mb-3 col-md-6">
                                                <label>Image</label>
												<UploadFile
													variant="button"
													defaultImage={imageValue}
													defaultValue={imageValue}
													setFile={(image) => {
														handleChange({ image })
													}}
												/>
											</div>
										</div>
										<div className="form-row mb-3">
										</div>
										<Button className="mr-2" variant="success" onClick={handleSave}>
											Save
										</Button>
										<Button variant="danger" onClick={handleCancel}>
											Cancel
										</Button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	)
}

export default PerksForm
