import { useEffect, useState } from 'react'
import useAxios from 'hooks/useAxios'
import { showMessage } from 'utils/notification.util'
import { getPaginatedPerksTemplatesService, deletePerkTemplateService } from 'services/PerksService'
import useAxiosV2 from 'hooks/useAxiosV2'
import { useConfirmModal } from 'components/common/confirmModal'

const usePerksProgram = () => {
	const [search, setSearch] = useState('')
	const [limit, setLimit] = useState(5)
	const [offset, setOffset] = useState(0)
	const [templates, setTemplates] = useState({
		rows: [],
		count: 0,
	})
	const [templateToDelete, setTemplateToDelete] = useState(null)
	const { isOpen, handleClose, handleOpen } = useConfirmModal()

	const { axiosService } = useAxiosV2()

	const afterFetchTemplates = (data, error) => {
		if (!error) {
			setTemplates(data)
		}
	}

	const handleDelete = (id) => {
		setTemplateToDelete(id)
		handleOpen()
	}

	const handleConfirmDelete = () => {
		axiosService(deletePerkTemplateService(templateToDelete), (_data, error) => {
			if (!error) {
				const message = 'Template deleted successfully!'
				showMessage({ message })
				fetchTemplates()
				handleClose()
			}
		})
	}

	const [{ loading: loadingTemplates }, fetchTemplates] = useAxios(
		getPaginatedPerksTemplatesService({ limit, offset, search }),
		afterFetchTemplates
	)

	const loading = loadingTemplates

	useEffect(() => {
		fetchTemplates()
	}, [limit, offset, search])

	return {
		loading,
		data: templates,
		limit,
		setLimit,
		setOffset,
		setSearch,
		handleDelete,
		isOpen,
		handleClose,
		handleConfirmDelete
	}
}

export default usePerksProgram
