import { Row, Col, Card, Button } from 'react-bootstrap'
import usePerksTemplates from './usePerksTemplates'
import { formatDate } from 'utils/date.utils'
import { CustomTable } from 'components/common/customTable'
import PageTitle from 'jsx/layouts/PageTitle'
import { SearchField } from 'components/common/searchField'
import styles from './PerksTemplates.module.scss'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { ConfirmModal } from 'components/common/confirmModal'

const PerksTemplates = () => {
	const {
		data,
		loading,
		limit,
		setOffset,
		setLimit,
		setSearch,
		handleDelete,
		isOpen,
		handleClose,
		handleConfirmDelete,
	} = usePerksTemplates()

	const columns = [

		{
			header: 'Name',
			render: (row) => row.name,
		},
		{
			header: 'Description',
			render: (row) => row.description,
		},
		{
			header: 'Start Date',
			render: (row) => formatDate(row.startDate, 'inputDate'),
		},
		{
			header: 'End Date',
			render: (row) => formatDate(row.endDate, 'inputDate'),
		},
		{
			header: 'Quantity',
			render: (row) => row.qty
		},
		{
			header: 'Action',
			render: (row) => (
				<div className="d-flex">
					<Link to={`edit-perks-template/${row.id}`} className="btn btn-primary shadow btn-xs sharp mr-1">
						<i className="fa fa-pencil"></i>
					</Link>
					<Button variant="danger" className="btn-xs sharp mr-1" onClick={() => handleDelete(row.id)}>
						<i className="fa fa-trash"></i>
					</Button>
				</div>
			),
		},
	]

	return (
		<>
			<PageTitle title={'Perks Templates'} />
			<Row className={styles.project_table}>
				<Col lg={12}>
					<Card>
						<Card.Header>
							<Card.Title>Current Templates</Card.Title>
							<Link to="add-perks-template">
								<Button className="mr-2" variant="success">
									Add Template
								</Button>
							</Link>
							<SearchField {...{ setSearch }} />
						</Card.Header>
						<Card.Body>
							<CustomTable
								{...{
									data,
									loading,
									limit,
									setLimit,
									setOffset,
									columns,
								}}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
			<ConfirmModal
				isOpen={isOpen}
				handleClose={handleClose}
				handleConfirm={handleConfirmDelete}
				text="Are you sure you want to delete this template?"
				confirmText="Delete"
				cancelText="Cancel"
			/>
		</>
	)
}

export default PerksTemplates
