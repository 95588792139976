import { buildFormData } from 'utils/form.util'

export const getProjects = (params) => ({
	method: 'get',
	url: '/project',
	params,
	autoexec: false,
})

export const getProjectCampaigns = (params) => ({
	method: 'get',
	url: '/project/campaigns',
	params,
	autoexec: false,
})

export const getContestProjects = (params) => ({
	method: 'get',
	url: '/project/contest',
	params,
	autoexec: false,
})

export const updateProjectService = (data) => ({
	method: 'put',
	url: `/project`,
	data,
	autoexec: false,
})

export const getProjectById = (autoexec, id) => ({
	method: 'get',
	url: `/project/${id}`,
	autoexec,
})

export const editProject = (data) => {
	const fields = [
		'id',
		'title',
		'trailer',
		'budget',
		'treatment',
		'stageId',
		'goScoreStageId',
		'statusId',
		'typeId',
		'approval',
		'staffPick',
		'logline',
		'script',
		'isDecayActive',
		'decayEffectiveDate'
	]
	const files = ['poster', 'coverImg']

	const formData = buildFormData(data, fields, files)
	return {
		method: 'put',
		url: '/project',
		data: formData,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		autoexec: false,
	}
}
